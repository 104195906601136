import { Outlet } from "react-router-dom";
import Footer  from "./components/Footer";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Outlet></Outlet>
      {/* <Footer /> */}

    </div>
  );
}

export default App;
